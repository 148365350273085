<template>
  <v-card
    class="mx-auto"
    color="#385F73"
    dark
    height="250px"
  >
    <v-card-text>
      <h2 class="text-center mt-4">
        Protocolo de acción para el sector servicios ante Covid-19
      </h2>
    </v-card-text>
    <v-card-actions class="d-flex justify-center mb-2">
      <v-btn
        href="https://coronavirus.jalisco.gob.mx/wp-content/uploads/2020/05/CCG-Protocolo-de-Servicios-COVID-191-1.pdf"
        target="_blank"
        outlined
        rounded
        small
      >
        <v-icon> {{ icons.mdiFormatHorizontalAlignRight }}</v-icon> Ver
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mdiFormatHorizontalAlignRight } from '@mdi/js'

export default {
  components: {},
  data() {
    return {
      datacollection: null,
      loading: true,
      error: false,
      errMessage: '',
    }
  },
  mounted() {
    this.fillData()
  },
  inject: {
    theme: {
      default: { isDark: false },
    },
  },
  methods: {
    fillData() { },
  },
  setup() {
    return {
      icons: {
        mdiFormatHorizontalAlignRight,
      },
    }
  },
}
</script>
