<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        md="12"
      >
        <encuesta></encuesta>
      </v-col>
      <v-col
        cols="12"
        md="3"
      >
        <sector-servicios></sector-servicios>
      </v-col>
      <v-col
        cols="12"
        md="3"
      >
        <sector-comercio></sector-comercio>
      </v-col>
      <v-col
        cols="12"
        md="3"
      >
        <secto-turismo></secto-turismo>
      </v-col>
      <v-col
        cols="12"
        md="3"
      >
        <iglesias></iglesias>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import SectorServicios from './SectorServicios'
import SectorComercio from './SectorComercio'
import SectoTurismo from './SectoTurismo'
import Iglesias from './Iglesias'
import Encuesta from '../resumen/Encuesta'

export default {
  components: {
    Iglesias,
    SectoTurismo,
    SectorComercio,
    SectorServicios,
    Encuesta,
  },
  mounted() { },
  methods: {},
  setup() { },
}
</script>
